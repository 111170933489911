import {useApplication} from '../providers/ApplicationProvider';
import {observer} from 'mobx-react';
import useFeature from '../hooks/useFeature';
import {useNavigate, useParams} from 'react-router-dom';
import MedicalRecordFeature from '../features/MedicalRecordFeature';
import RecordButton from '../components/audio/RecordButton';
import PatientInfoBox from '../components/PatientInfoBox';
import {useVoiceAudioRecorder} from '../providers/VoiceAudioRecorderProvider';
import * as React from 'react';
import {ScreenTitleBar} from '../components/ScreenTitleBar';
import NoteList from '../components/entries_list_items/NoteList';
import PatientOfDoctor from '../app/audioOwners/PatientOfDoctor';
import CreateTextNote from '../components/CreateTextNote';
import FileUploadBox from '../components/FileUploadBox';
import DashboardMainContainer from '../components/DashboardMainContainer';
import {NewTextNoteButton} from '../components/NewTextNoteButton';
import {UploadFileButton} from '../components/UploadFileButton';
import Box from '@mui/material/Box';
import {MaiaTabs} from '../components/MaiaTabs';
import SharedNotebook from '../components/SharedNotebook';
import SearchBar from '../components/SearchBar';
import MaiaFilledIconButton from '../components/buttons/MaiaFilledIconButton';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import NotesSearchResult from '../components/entries_list_items/NotesSearchResult';
import Patient from '../app/audioOwners/Patient';
import ChatBox from "../components/chat/ChatBox";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const MedicalRecordTab = observer(({feature}) => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
      {
        feature.patient &&
        <NoteList
          isRetrievingData={feature.isRetrievingData}
          notes={feature.notes}
          tags={feature.tags}
          updateEntryTags={feature.updateEntryTags}
          audioNotesOwner={new PatientOfDoctor(feature.patient)}
          updateEntryTitle={feature.updateEntryTitle}
          addEntryToChatbot={feature.addEntryToChatbot}
          onDeleteAudioNote={feature.deleteAudioNoteAndRefresh}
          onDeleteTextNote={feature.deleteTextNoteAndRefresh}
          onDeleteFile={feature.deleteFileAndRefresh}
          listStyles={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}
        />
      }
    </Box>
  );
});

const PatientNotebookTab = observer(({feature}) => {
  return <Box>
    {feature.patient &&
      <SharedNotebook
        patientId={feature.patientId}
        patient={feature.patient}
        notes={feature.patientSharedNotes}
        isRetrievingData={feature.isRetrievingData}
        audioNotesOwner={new PatientOfDoctor(feature.patient)}
        listStyles={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}
      />
    }
  </Box>;
});

function SeePatientMedicalRecordScreen() {
  const application = useApplication();
  const navigator = useNavigate();
  const voiceAudioRecorder = useVoiceAudioRecorder();
  const {patientId} = useParams();
  const feature = useFeature(() => new MedicalRecordFeature(patientId, application, navigator, voiceAudioRecorder), [application, patientId]);
  const style = styles();

  return feature && (
    <Box>
      <DashboardMainContainer>
        <ScreenTitleBar title={`Ficha de paciente`}>
          <Box sx={style.actionsButtonsContainer}>
            <UploadFileButton onClick={() => feature.toggleShowUploadFieldForm()}/>
            <NewTextNoteButton onClick={() => feature.toggleShowTextNoteForm()}/>
            {feature.patient && <RecordButton person={new PatientOfDoctor(feature.patient)}/>}
            {feature.mustShowPatientInvite &&
              <MaiaFilledIconButton icon={ContentPasteGoIcon}
                                    title={'Copiar invitación'}
                                    onClick={feature.copyInviteForPatient}/>
            }
            <MaiaFilledIconButton
              icon={LocalHospitalIcon}
              title={'Comenzar nueva consulta'}
              onClick={() => feature.goToStartNewConsultation(feature.patient.identification_number)}
            />
          </Box>
        </ScreenTitleBar>

        {feature.patient && <PatientInfoBox patient={feature.patient}/>}

        {
          feature.showTextNoteForm &&
          <CreateTextNote feature={feature}/>
        }
        {
          feature.showUploadFileForm &&
          <FileUploadBox
            onFileUploaded={feature.uploadFileToNotebook}
            onCancel={() => feature.toggleShowUploadFieldForm()}/>
        }

        <Box style={{display: 'flex', gap: '25px'}}>
          <Box style={{flex: 1}}>
            <SearchBar onSearch={feature.searchEntries}
                       searchDisabled={feature.isSearchingResults}
                       placeholder={"Que estás buscando? Por ejemplo: estudios de sangre"}/>
            <NotesSearchResult feature={feature}
                               showSearchResults={feature.showSearchResults}
                               closeSearchResults={feature.closeSearchResults}
                               isRetrievingData={feature.isRetrievingData}
                               searchedEntries={feature.searchedEntries}
                               owner={new Patient()}/>
            <MaiaTabs
              tabs={[
                {title: "Ficha médica", content: () => <MedicalRecordTab feature={feature}/>},
                {title: "Legajo del paciente", content: () => <PatientNotebookTab feature={feature}/>}
              ]}
            />
          </Box>
          <ChatBox chatBot={feature.chatBot}
                   showDocumentSearch={false}/>
        </Box>
      </DashboardMainContainer>
    </Box>
  );
}

export default observer(SeePatientMedicalRecordScreen);

const styles = () => ({
    actionsButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '0.5rem'
    },
    chatContainer: {
      flex: 1,
      gap: '15px',
      display: 'flex',
      flexDirection: 'column'
    }
  })
;