import {useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import FilledTextButton from '../../components/buttons/FilledTextButton';
import LineTextButton from '../../components/buttons/LineTextButton';
import * as React from 'react';
import ButtonsContainer from "../../components/medicalConsultationStepsComponents/ButtonsContainer";
import StepsMainContainer from "../../components/medicalConsultationStepsComponents/StepsMainContainer";
import {ScreenTitleBar} from "../../components/ScreenTitleBar";
import FormCheckboxesField from '../../components/fields/FormCheckboxesField';

function RisksFactorsStep({patientForm, onBack, onContinue}) {
  const theme = useTheme();
  const style = styles(theme);

  const riskFactorsForm = () => {
    return (
      <section style={style.formContainer}>
        <div style={style.rowContainer}>
          <FormCheckboxesField field={patientForm.getFieldByName('riskFactors')} label="Factores de riesgo"/>
        </div>
      </section>
    )
  }

  return (
    <StepsMainContainer>
      <ScreenTitleBar title={'Antecedentes'} showBackButton={false}/>

      {riskFactorsForm()}

      <ButtonsContainer>
        <LineTextButton label="Volver" light onClick={onBack}/>
        <FilledTextButton label="Continuar" disabled={false} onClick={onContinue}/>
      </ButtonsContainer>
    </StepsMainContainer>
  );

}

export default observer(RisksFactorsStep);

const styles = () => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '10px',
    width: '70%',
  }
});