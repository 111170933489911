import OptionField from './OptionField';
import OptionsValidator from "./validators/OptionsValidator";
import {computed, makeObservable, observable} from "mobx";

export default class OptionLabeledField extends OptionField {
  constructor(labeledOptions, initialValue = undefined) {
    const labeledValueOptions = labeledOptions.map((option) => {
      return option.value;
    });
    super(labeledValueOptions, initialValue);
    this._options = labeledOptions;

    makeObservable(this, {
      _options: observable,
      labelValue: computed,
    });
  }

  updateOptions(options) {
    this._options = options;
    this._valueOptions = options.map((option) => {
      return option.value;
    });
    this._validators = [new OptionsValidator(this._valueOptions)];
  }

  options() {
    return this._options;
  }

  get labelValue() {
    return this._options.find((option) => option.value === this.value).label;
  }
}
