import {action, computed, makeObservable, observable} from 'mobx';

export default class FormField {
  constructor(initialValue = undefined) {
    this._value = initialValue !== undefined ? initialValue : this.defaultValue();
    this._errors = [];
    this._validators = [];
    this._optional = false;

    makeObservable(this, {
      _value: observable,
      _errors: observable,
      hasErrors: computed,
      isValid: computed,
      value: computed,
      errors: computed,
      updateValue: action,
      clear: action,
      setErrors: action,
      validate: action,
    });
  }

  get value() {
    return this._value;
  }

  get errors() {
    return this._errors;
  }

  defaultValue() {
    return "";
  }

  setErrors(errors) {
    this._errors = errors;
  }

  updateValue(value) {
    this._value = value;
  }

  clear() {
    this._value = this.defaultValue();
    this._errors = [];
  }

  validate() {
    if (this._value === this.defaultValue()) {
      return;
    }

    this._errors = [];
    this._validators.forEach((validator) => {
      if (!validator.isValid(this._value)) {
        this._errors.push(validator.errorMessage())
      }
    })
  }

  get isValid() {
    if (this._optional) {
      if (!this.value || this.value === this.defaultValue()) {
        return true;
      }
    }
    return this._validators.every((validator) => validator.isValid(this._value));
  }

  get hasErrors() {
    return this._errors.length > 0;
  }

  get isRequired() {
    return !this._optional;
  }

  asOptional() {
    this._optional = true;
    return this;
  }

  get isEmpty() {
    return this._value === this.defaultValue();
  }
}
