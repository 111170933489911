import {useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react';
import FilledTextButton from '../../components/buttons/FilledTextButton';
import LineTextButton from '../../components/buttons/LineTextButton';
import * as React from 'react';
import Box from "@mui/material/Box";
import ButtonsContainer from "../../components/medicalConsultationStepsComponents/ButtonsContainer";
import StepsMainContainer from "../../components/medicalConsultationStepsComponents/StepsMainContainer";
import {ScreenTitleBar} from "../../components/ScreenTitleBar";
import {presentAgeOfPersonFromDateString} from "../../libs/utils";

function InformationSummaryStep({patientForm, symptomsForm, onBack, onContinue}) {
  const theme = useTheme();
  const style = styles(theme);

  const renderRow = (title, value) => {
    return (
      <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <Typography color={theme.palette.lilac.main} variant="body1" fontWeight={'800'}>
          {title}
        </Typography>
        <Typography color={theme.text.primary} variant="body1">
          {value}
        </Typography>
      </Box>
    )
  }

  return (
    <StepsMainContainer>
      <ScreenTitleBar title={'Resumen'} showBackButton={false}/>


      <Box sx={style.boxContainer}>
        {renderRow('Edad:', presentAgeOfPersonFromDateString(patientForm.getFieldValue('birthdate')))}
        {renderRow('Razón de consulta:', symptomsForm.getFieldValue('symptoms'))}
        {renderRow('Categorización de aparición de los síntomas:', symptomsForm.getFieldByName('appearedDaysAgo').labelValue)}
        {renderRow('Factores de riesgo:', patientForm.getFieldByName('riskFactors').labeledValues)}
        {renderRow('Historial familiar:', patientForm.getFieldValue('familyHistory'))}
      </Box>

      <ButtonsContainer>
        <LineTextButton label="Volver" light onClick={onBack}/>
        <FilledTextButton label="Guardar" disabled={false} onClick={onContinue}/>
      </ButtonsContainer>
    </StepsMainContainer>
  );

}

export default observer(InformationSummaryStep);

const styles = (theme) => ({
  boxContainer: {
    padding: '25px',
    border: 'solid 2px',
    borderColor: theme.palette.primary.main,
    flexDirection: 'column',
    gap: ' 10px',
    width: 'fit-content',
    display: 'flex',
    background: theme.palette.primary.dark,
  }
});