import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';
import {copyToClipboard, presentCurrentDateAndTime} from "../../libs/utils";
import ChatHistory from "../../domain/ChatHistory";
import {toast} from "react-toastify";
import {action, computed, makeObservable, observable} from "mobx";

export class ChatbotBase {
  constructor(apiClient, saveConversation, onConversationSaved = undefined) {
    this._chatHistory = new ChatHistory();
    this._isRetrievingAnswer = false;
    this._onConversationSaved = onConversationSaved;
    this._saveConversation = saveConversation;
    this._apiClient = apiClient;

    makeObservable(this, {
      _isRetrievingAnswer: observable,
      isRetrievingAnswer: computed,
      ask: action,
    });

  }

  ask = (question) => {
    this._chatHistory.addQuestion(question);
    this._isRetrievingAnswer = true;
    return new Promise((resolve, reject) => {
      const responseHandler = this._chatbotResponseHandler(resolve, reject);
      this._ask(question, responseHandler);
    });
  }

  searchRemoteLibrary = (question) => {
    this._chatHistory.addQuestion(question);
    this._isRetrievingAnswer = true;
    return new Promise((resolve, reject) => {
      const responseHandler = this._chatbotResponseHandler(resolve, reject);
      this._apiClient.searchRemoteLibrary(question, responseHandler);
    });
  }

  _ask() {
    throw new Error("Method not implemented.");
  }

  onCopy = async (textForClipboard) => {
    await copyToClipboard(textForClipboard, () => {
      toast.success("Mensaje guardado en portapapeles.");
    }, () => {
    });
  };

  async saveConversation() {
    const responseHandler = new ApiResponseHandler({
      handlesSuccess: () => {
        this._onConversationSaved?.();
        toast.success("Conversación guardada existosamente en nota de texto.");
      },
      handlesError: () => {
        toast.error("No se puedo guardar la nota de texto.")
      }
    });
    const title = this._titleForConversation();
    let content = this._contentForConversation(this._chatHistory);
    this._saveConversation(title, content, responseHandler);
    this._chatHistory.clear();
  }

  get chatHistory() {
    return this._chatHistory;
  }

  get isRetrievingAnswer() {
    return this._isRetrievingAnswer;
  }

  get hasEntriesUsed() {
    return false;
  }

  _titleForConversation() {
    return `Asistente: ${presentCurrentDateAndTime()}`;
  }

  _contentForConversation(chatHistory) {
    let content = ""
    chatHistory.allMessages.map((message) => {
      const sender = message.wasSentByDoctor ? 'Yo' : 'Asistente virtual';
      content += `**${sender}**: ${message.text}\n\n\ `;
    });
    return content;
  }

  _chatbotResponseHandler(resolve, reject) {
    return new ApiResponseHandler({
      handlesError: (error) => {
        reject(error);
      },
      handlesSuccess: (response) => {
        this._chatHistory.addAssistantAnswer(response.answer(), response.entriesUsed());
        this._isRetrievingAnswer = false;
        resolve(response.answer());
      }
    });
  }
}