import {action, computed, makeObservable, observable} from "mobx";
import ApiResponseHandler from '@eryxcoop/appyx-comm/src/errors/ApiResponseHandler';
import TextField from "../libs/forms/fields/TextField";

export default class PatientsListFeature {
  constructor(application, navigator) {
    this._application = application;
    this._navigator = navigator;
    this._patients = [];
    this._isRetrievingData = false;
    this._originalPatients = []; // TODO: remove when we implement backend search
    this._searchField = new TextField(3, 20);

    makeObservable(this, {
      _patients: observable,
      _isRetrievingData: observable,
      _searchField: observable,
      _updatePatients: action,
      isRetrievingData: computed,
      patients: computed,
      searchField: computed,
      getPatients: action,
    })
  }

  load() {
    this.getPatients();
  }

  get searchField() {
    return this._searchField;
  }

  set searchField(search) {
    this._searchField = search
  }

  getPatients = async () => {
    const responseHandler = new ApiResponseHandler({
      handlesError: () => {
        this.isRetrievingData = false;
      },
      handlesSuccess: (response) => {
        const patients = response.patients()
        this._updatePatients(patients);
        this._originalPatients = patients;
        this.isRetrievingData = false;
      }
    });
    this.isRetrievingData = true;
    this._application.apiClient().getDoctorsPatients(responseHandler);
  }

  goToStartNewConsultation = () => {
    this._navigator('new-consultation');
  }

  _updatePatients(newPatients) {
    this._patients = newPatients;
  }

  search = (search) => {
    // Just for now, until we implement backend search
    const searchLowerCase = search.toLowerCase();
    const patients = this._originalPatients.filter(
      (patient) => {
        const fullName = `${patient.first_name} ${patient.last_name}`;
        return patient.first_name.toLowerCase().includes(searchLowerCase)
          || patient.last_name.toLowerCase().includes(searchLowerCase)
          || fullName.toLowerCase().includes(searchLowerCase)
          || patient.email.toLowerCase().includes(searchLowerCase)
          || patient.identification_number.toLowerCase().includes(searchLowerCase)
      }
    );
    this._updatePatients(patients);
  }

  get isRetrievingData() {
    return this._isRetrievingData;
  }

  get patients() {
    return this._patients;
  }

  set isRetrievingData(value) {
    this._isRetrievingData = value;
  }
}