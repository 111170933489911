import {observer} from 'mobx-react';
import FormTextField from '../../components/fields/FormTextField';
import {ScreenTitleBar} from '../../components/ScreenTitleBar';
import FilledTextButton from "../../components/buttons/FilledTextButton";
import ButtonsContainer from "../../components/medicalConsultationStepsComponents/ButtonsContainer";
import * as React from "react";
import StepsMainContainer from "../../components/medicalConsultationStepsComponents/StepsMainContainer";
import FormSelectField from "../../components/fields/FormSelectField";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material";

function PatientIdStep({patientForm, onContinue, canContinue}) {
  const theme = useTheme();
  const style = styles(theme);

  const identificationNumberField = patientForm.getFieldByName('identificationNumber');
  const existingPatientField = patientForm.getFieldByName('existingPatient');

  return (
    <StepsMainContainer>
      <ScreenTitleBar title={'Ingresa DNI'}/>

      <Box sx={style.formContainer}>
        <FormTextField field={identificationNumberField}
                       disabled={!existingPatientField.isEmpty && existingPatientField.isValid}
                       label={"DNI"}/>

        <FormSelectField label={'Paciente'}
                         disabled={!identificationNumberField.isEmpty && identificationNumberField.isValid}
                         field={existingPatientField}
        />
      </Box>

      <ButtonsContainer>
        <FilledTextButton label="Continuar" disabled={!canContinue()} onClick={onContinue}/>
      </ButtonsContainer>
    </StepsMainContainer>
  );
}


export default observer(PatientIdStep);

const styles = (theme) => ({
  formContainer: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
});
